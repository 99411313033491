<template>
  <div class="box">
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addPage">添加页面</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
          prop="title"
          label="标题"
          width="">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="设为首页"
          align="center"
          width="145">
        <template slot-scope="scope">
          {{ scope.row.is_home_page==1?'首页':'-' }}
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="145">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editRow(scope.row)">编辑</el-button>
          <el-popconfirm
              title="确定删除吗？"
              @confirm="delRow(scope.row)"
          >
            <el-button type="text" size="small" slot="reference" style="color: red; margin-left: 10px;">删除</el-button>
          </el-popconfirm>
          <el-popconfirm
              title="确定设为首页吗？"
              @confirm="setHome(scope.row.id)"
          >
            <el-button type="text" size="small" slot="reference" style="margin-left: 10px;">设为首页</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      code: '',
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList()
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    addPage() {
      this.$router.push({
        name: 'shop_design_info'
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords
      }
      this.$api.shop.designIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    },
    editRow(item) {
      this.$router.push({name: 'shop_design_info', query: {id: item.id}})
    },
    delRow(item) {
      var that = this
      this.$api.shop.designDel({id: item.id}, function (res) {
        if(res.errcode == 0) {
          that.success(res.errmsg)
          that.getList()
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    setHome(id) {
      var that = this
      this.$api.shop.designSetHome({id: id}, function (res) {
        if(res.errcode == 0) {
          that.success(res.errmsg)
          that.getList()
        } else {
          that.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}
.box img {
  border-radius: 50%;
  float: left;
  margin-right: 20px;
}
</style>
